import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`老二次元人类`}</p>
    <p>{`启蒙的动画是犬夜叉，从火影、死神、高达Seed/Destiny、钢之炼金术士、鲁路修、凉宫团长、传颂之物、夏目友人帐、寒蝉、花未名、魔法少女小圆等等等一直到现在进击的巨人、鬼灭之刃、咒术回战之类的热门作品一直都没落下，虽然现在追的剧比以前少了很多，但依然保持对动画的热爱，有些新年、感动、纯真之有动画才给得了，保持热爱，保持年轻。`}</p>
    <p>{`游戏也不用说，喜欢尝试各种类型的，从PC到主机到网游。 红警、CS自然是PC游戏的启蒙。 网游应该是占据了青春期最多的闲暇时间，从精灵到仙境传入到魔兽世界到剑灵，留了太多美好的回忆。 主机从PS1到PS5，每一台机器都有，心目的无法愉悦的神作是北欧女神、恶魔城月下夜想曲，在那个非常青涩的年纪爱上游戏，可能就像初恋，总是最甜的。现在自由的时间实在太少了，也只能玩玩3A了。`}</p>
    <p>{`近些年最爱的游戏是战地5，也是自己入坑手柄FPS的游戏。只是可惜战地2042一点都没有战地的味道，最近战地也玩的很少。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      